<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />


    <v-card>
      <v-card-text>

        <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            color="pink"
            small
            v-for="item in data"
            :key="item.id"
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>{{item.created_at}}</strong>
              </v-col>
              <v-col>
                <strong>{{item.root}}</strong>
                <div class="text-caption">
                  Testowy alert
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>

      </v-card-text>
    </v-card>

    <!-- <v-alert
      dense
      v-for="item in data" 
      :type="item.type"
      :key="item.id"
      dismissible
      @input="close_alert(item.id)"
    >
      {{item.message}}
    </v-alert> -->
    

  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Powiadomienia',
        disabled: true,
        to: '/powiadomienia',
      },
    ],

    data: [
      {
        id: 1,
        type: 4,
        root: 'task',
        info: 1,
        read_at: null,
        assign_user: 1,
        created_at: '05-07-2021',
        updated_at: '05-7-2021'
      }
    ],
    
    show: false,
    search: ''
  }),
  methods: {
    invoiceClick(value) {
      this.$router.push('/koszty-projektu/'+value.project_id);
    },
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/dash/notify', data: {}, method: 'GET' })
      .then(resp => {
        this.data = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
</style>